import { ChangeDetectorRef, Component, Inject, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import {
  PaginationClass,
  PaginationControl,
  RedFlagTransactionHistory,
  RoleAuth,
  TranPaginationClass,
  TranPaginationControl,
  TransactionHistory,
} from "../../models";
import * as Papa from "papaparse";
import { AssociatesService } from "src/app/bas/associates/associates.service";
import { SharedService } from "../../services";
import { Subscription } from "rxjs";
import { SessionStorage } from "ngx-webstorage";
import { FormControl, FormGroup, UntypedFormGroup } from "@angular/forms";
import moment, { Moment } from "moment";
import { DatePipe } from "@angular/common";
import { PaginationInstance } from "ngx-pagination";
import { DownloadComponent } from "../download/download.component";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: "bas-transaction-history-dialog",
  templateUrl: "./transaction-history-dialog.component.html",
  styleUrls: ["./transaction-history-dialog.component.scss"],
})
export class TransactionHistoryDialogComponent {
  @SessionStorage("mod") public modules: any;
  public permissions = new RoleAuth([]);

  public pageDatas = new TranPaginationClass({ pageSize: 4 });
  public pageControls = new TranPaginationControl();
  public selectedTab;
  public rangeLabel;
  public startval = "";
  public endval = "";
  public count = 0;
  public sortName;
  public order = "desc";
  public start = "";
  public isEventLabel = false;
  public pagination = {
    limit: this.pageDatas.pageSize,
    offset: this.pageDatas.pageNumber - 1,
    searchText1: "",
  };
  public id;
  public startSelected;
  public endSelected;
  startDates: any = new Date("2019-01-01");
  endDates: any = new Date();
  public searchForm: UntypedFormGroup;
  public contentLoaded = false;
  public history = [];
  public getSubscription: Subscription;
  public search_Subscription: Subscription;
  selectedrange: { startDate: Moment; endDate: Moment };
  selectedValue: any = { startDate: null, endDate: null };
  public config: PaginationInstance = {
    id: "advanced",
    itemsPerPage: this.pageDatas.pageSize,
    totalItems: this.pageDatas.totalItems,
    currentPage: this.pageDatas.pageNumber,
  };
  ranges: any = {
    All: [moment("2019-01-01T00:00:00")],
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last 3 Month": [
      moment().subtract(3, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  selectedRange = "Today: " + this.datePipe.transform(Date.now(), "MMM dd");
  constructor(
    public dialogRef: MatDialogRef<TransactionHistoryDialogComponent>,
    private associateService: AssociatesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private ngZone: NgZone
  ) {
    this.searchForm = new FormGroup({
      searchText1: new FormControl(),
    });

    // this.listenToEvents();

    this.checkModulePermissions();
  }
  ngOnInit() {
    let startdate = moment("2019-01-01T00:00:00");
    this.selectedValue.startDate = startdate;
    this.selectedValue.endDate = moment();
    // this.selectedValue.startDate = moment().subtract(2, 'year').startOf('day');
    this.cd.detectChanges();

    this.sharedService.total = true;
    this.id = this.data.id;
    this.selectedTab = this.data.selectedTab;
    if (this.selectedTab == "associates") {
      this.sortName = "last_updated_at";
      this.getHistory();
    } else if (this.selectedTab == "redFlag") {
      this.sortName = "updated_at";
      this.getRedFlagHistory();
    } else if (this.selectedTab == "onboarding") {
      this.sortName = "last_updated_at";
      this.getOnboardHistory();
    }
  }
  checkModulePermissions() {
    if (this.modules) {
      const currentModule = this.modules.filter(
        (module) =>
          module.modulename.toLowerCase() === "associates" &&
          module.resources.filter(
            (resource) => resource.resourcename.toLowerCase() === "associates"
          ).length > 0
      );
      if (currentModule.length > 0) {
        const currentResource = currentModule[0].resources.filter(
          (resource) => resource.resourcename.toLowerCase() === "associates"
        );
        this.permissions = new RoleAuth(currentResource[0].permissions);
        if (this.permissions.read) {
          this.pageDatas.searchText1 = "";
          if (this.selectedTab == "associates") {
            this.getHistory();
          } else if (this.selectedTab == "redFlag") {
            this.getRedFlagHistory();
          } else if (this.selectedTab == "onboarding") {
            this.getOnboardHistory();
          } // if(!this.getAsscSubscription){
          this.listenToEvents();
          // }
        }
        // if (this.permissions.create) {
        //   this.associateService.setSubscription('getAssociate', { showAddAsscBtn: true });
        // }
      }
    }
  }

  listenToEvents() {
    this.getSubscription = this.associateService
      .getSubscription("getAssociate")
      .subscribe((res) => {
        if (res && res.hasOwnProperty("refresh")) {
          if (this.selectedTab == "associates") {
            this.getHistory();
          } else if (this.selectedTab == "redFlag") {
            this.getRedFlagHistory();
          } else if (this.selectedTab == "onboarding") {
            this.getOnboardHistory();
          }
        }
      });

    this.search_Subscription = this.associateService
      .getSubscription("search")
      .subscribe((res) => {
        if (
          res &&
          res.hasOwnProperty("associates") &&
          res.hasOwnProperty("searchText1")
        ) {
          this.pagination.searchText1 = res.searchText1.trim();
          this.pagination.offset = 0;
          if (this.selectedTab == "associates") {
            this.getHistory();
          } else if (this.selectedTab == "redFlag") {
            this.getRedFlagHistory();
          } else if (this.selectedTab == "onboarding") {
            this.getOnboardHistory();
          }
        } else {
          if (
            res &&
            res.hasOwnProperty("redflag") &&
            res.hasOwnProperty("searchText1")
          ) {
            // this.sharedService.display(true)
            this.pagination.searchText1 = res.searchText1.trim();
            this.pagination.offset = 0;
            this.getRedFlagHistory();
          }
        }
      });
  }
  ngOnDestroy() {
    if (this.getSubscription) {
      this.getSubscription.unsubscribe();
    }
    if (this.search_Subscription) {
      this.search_Subscription.unsubscribe();
    }
  }
  getRedFlagHistory() {
    this.isEventLabel = false;
    // this.sharedService.display(true);
    this.contentLoaded = false;
    this.history = [];
    let defaultStart = this.convertDateFormat(this.startDates, "start");
    if (!this.startval && this.endval) {
      this.startval = defaultStart;
    }
    let request =
      "?limit=" +
      this.pagination.limit +
      "&offset=" +
      this.pagination.offset +
      "&search=" +
      this.pagination.searchText1 +
      "&startDate=" +
      this.startval +
      "&endDate=" +
      this.endval;

    this.associateService
      .get(`associates/blocked/history/${this.data.id}` + request, true)
      .subscribe(
        (response) => {
          this.history = [];
          response.data.forEach((user) => {
            this.history.push(new RedFlagTransactionHistory(user));
          });
          this.pageDatas.totalItems = response.total;
          this.config.totalItems = response.total;

          // this.sharedService.display(false);
          this.contentLoaded = true;
        },
        () => {
          this.resetPage();
        }
      );
  }
  getHistory() {
    this.isEventLabel = false;
    this.history = [];
    // this.sharedService.display(true);
    this.contentLoaded = false;
    let defaultStart = this.convertDateFormat(this.startDates, "start");
    if (!this.startval && this.endval) {
      this.startval = defaultStart;
    }
    let request =
      "?limit=" +
      this.pagination.limit +
      "&offset=" +
      this.pagination.offset +
      "&search=" +
      this.pagination.searchText1 +
      "&startDate=" +
      this.startval +
      "&endDate=" +
      this.endval +
      "&order=" +
      this.order +
      "&sort=" +
      this.sortName;

    this.associateService
      .get(`associates/history/${this.data.id}` + request, true)
      .subscribe(
        (response) => {
          // this.history = [];
          this.pageDatas.totalItems = 0;
          this.pageDatas.totalItems = response.total;
          this.config.totalItems = response.total;

          response.data.forEach((user) => {
            this.history.push(new TransactionHistory(user));
          });
          // this.sharedService.display(false);
          this.contentLoaded = true;
        },
        () => {
          this.resetPage();
        }
      );
  }
  resetPage() {
    // this.allAssociateList = [];
    this.pageDatas.totalItems = 0;
    // this.sharedService.display(false);
    this.contentLoaded = true;
  }
  setCurrent(event) {
    this.pageDatas.pageNumber = Number(event);
    this.config.currentPage = this.pageDatas.pageNumber;
    this.pagination.offset = Number(event - 1);
    if (this.sortName || this.order) {
      if (this.selectedTab == "associates") {
        this.getHistory();
      } else if (this.selectedTab == "redFlag") {
        this.getRedFlagHistory();
      } else if (this.selectedTab == "onboarding") {
        this.getOnboardHistory();
      }
    } else {
      if (this.selectedTab == "associates") {
        this.getHistory();
      } else if (this.selectedTab == "redFlag") {
        this.getRedFlagHistory();
      } else if (this.selectedTab == "onboarding") {
        this.getOnboardHistory();
      }
    }
  }
  close() {
    this.dialogRef.close();
  }

  downRedFlagTran() {
    this.sharedService.display(true);
    const taskId = uuidv4()
    this.associateService
    .fileDownload(`associates/blocked/history/${this.id}/download?taskId=${taskId}`, true)
    .subscribe((res) => {
        this.close()
        this.sharedService.display(false);
        if (res) {
          const currentDate = new Date()
            .toString()
            .split(" GMT")[0]
            .replace(/:/g, "-");
          this.dialog.open(DownloadComponent, {
            autoFocus: false,
            disableClose: true,
            panelClass: "download-dialog",
            backdropClass: "download-dialog-backdrop",
            data: {
              taskId,
              fileName: `transaction-${currentDate}.csv`,
            },
          });
        } else {
          this.sharedService.openSnackBar({ error: "No Data Found" });
        }
      });
  }
  downAsscTran() {
    this.sharedService.display(true);
    const taskId = uuidv4()
    this.associateService
    .fileDownload(`associates/history/${this.id}/download?taskId=${taskId}`, true)
    .subscribe((res) => {
      this.sharedService.display(false);
      this.close()
        if (res) {
          const currentDate = new Date()
            .toString()
            .split(" GMT")[0]
            .replace(/:/g, "-");

          this.dialog.open(DownloadComponent, {
            autoFocus: false,
            disableClose: true,
            panelClass: "download-dialog",
            backdropClass: "download-dialog-backdrop",
            data: {
              taskId,
              fileName: `transaction-${currentDate}.csv`,
            },
          });
        } else {
          this.sharedService.openSnackBar({ error: "No Data Found" });
        }
      });
  }
  onSearch(evnt?, canEmit?: boolean) {
    //debugger
    if (
      (evnt &&
        ((evnt.keyCode && evnt.keyCode === 13) ||
          evnt.target.value.trim() === "")) ||
      canEmit
    ) {
      let data = {
        searchText1: this.searchForm.value.searchText1,
      };
      this.pagination.searchText1 = this.searchForm.value.searchText1;
      if (
        evnt &&
        ((evnt.keyCode && evnt.keyCode === 13) ||
          evnt.target.value.trim() === "")
      ) {
        if (this.selectedTab == "onboarding") {
          this.getOnboardHistory();
        }
        // else if(this.selectedTab == 'onboarding'){
        //   this.getOnboardHistory();
        // }
        // else if(this.selectedTab == 'redFlag'){
        //   this.getRedFlagHistory();
        // }
      }
      data[this.selectedTab.toLowerCase()] = true;

      this.associateService.setSubscription("search", data);
    } else {
      this.associateService.setSubscription(
        "search",
        this.searchForm.value.searchText1
      );
    }
  }
  startDateChange(selectedDate: any) {
    const originalDateString = selectedDate.target.value;
    const convertedDateString = this.convertDateFormat(
      originalDateString,
      "start"
    );

    this.startSelected = convertedDateString;
    this.startval = this.startSelected;
    this.endval = this.convertDateFormat(this.endDates, "end");
    if (this.selectedTab == "redFlag") {
      this.getRedFlagHistory();
    } else if (this.selectedTab == "associates") {
      this.getHistory();
    } else if (this.selectedTab == "onboarding") {
      this.getOnboardHistory();
    }
  }
  endDateChange(selectedDate: any) {
    if (selectedDate.target.value) {
      const originalDateString = selectedDate.target.value;
      const convertedDateString = this.convertDateFormat(
        originalDateString,
        "end"
      );
      this.endSelected = convertedDateString;
      this.startval = this.startSelected;
      this.endval = this.endSelected;
      if (this.selectedTab == "redFlag") {
        this.getRedFlagHistory();
      } else if (this.selectedTab == "associates") {
        this.getHistory();
      } else if (this.selectedTab == "onboarding") {
        this.getOnboardHistory();
      }
    }
  }

  convertDateFormat(inputDate, type) {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-based
    const day = ("0" + dateObject.getDate()).slice(-2);
    if (type == "start") {
      const hours = "00";
      const minutes = "00";
      const seconds = "00";
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return formattedDate;
    }
    if (type == "end") {
      const hours = "23";
      const minutes = "59";
      const seconds = "59";
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return formattedDate;
    }
  }
  choosedDate(event) {
    if (event.startDate && event.endDate) {
      this.startval = new Date(event.startDate.$d).toISOString().slice(0, -5);
      this.endval = new Date(event.endDate.$d).toISOString().slice(0, -5);
      let start = this.datePipe.transform(event.startDate.$d, "dd MMM yyyy");
      let end = this.datePipe.transform(
        event.endDate.$d.setDate(event.endDate.$d.getDate() - 1),
        "dd MMM yyyy"
      );
      if (!this.isEventLabel) {
        this.rangeLabel = start + "-" + end;
      }
      if (this.selectedTab == "redFlag") {
        this.getRedFlagHistory();
      } else if (this.selectedTab == "associates") {
        this.getHistory();
      } else if (this.selectedTab == "onboarding") {
        this.getOnboardHistory();
      }
    }
  }
  rangeClicked(event) {
    if (event.label) {
      this.isEventLabel = true;
    } else {
      this.isEventLabel = false;
    }

    this.startval = new Date(event.dates[0].$d).toISOString().slice(0, -5);
    this.endval = new Date(event.dates[1].$d).toISOString().slice(0, -5);
    let val1 = this.datePipe.transform(event.dates[0].$d, "MMM dd");
    let val = event.dates[0].$d.setHours(
      event.dates[0].$d.getHours() - 5,
      event.dates[0].$d.getMinutes() - 30
    );
    let val2 = this.datePipe.transform(val);
    // let val2 = this.datePipe.transform(event.dates[1].$d, "MMM dd");
    if (event.label == "Today" || event.label == "Yesterday") {
      this.rangeLabel = event.label + ": " + val2;
    } else {
      this.rangeLabel = event.label;
    }
  }
  camelCaseToSnakeCase(input: string): string {
    return input.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
  }
  sortColumn(event) {
    let field = event.multisortmeta[0].field;
    if (field == "lastUpdatedAt" && this.selectedTab != "redFlag") {
      field = "last_updated_at";
    } else if (field == "lastUpdatedAt" && this.selectedTab == "redFlag") {
      field = "updated_at";
    }
    this.sortName = this.camelCaseToSnakeCase(field);
    this.count++;
    if (this.count % 2 != 0) {
      this.order = "asc";
    } else {
      this.order = "desc";
    }

    if (this.selectedTab == "redFlag") {
      this.getRedFlagHistory();
    } else if (this.selectedTab == "associates") {
      this.getHistory();
    } else if (this.selectedTab == "onboarding") {
      this.getOnboardHistory();
    }
  }
  getOnboardHistory(sorted?) {
    this.isEventLabel = false;
    this.history = [];
    // this.sharedService.display(true);
    this.contentLoaded = false;
    let defaultStart = this.convertDateFormat(this.startDates, "start");
    if (!this.startval && this.endval) {
      this.startval = defaultStart;
    }
    let request =
      "?limit=" +
      this.pagination.limit +
      "&offset=" +
      this.pagination.offset +
      "&search=" +
      this.pagination.searchText1 +
      "&startDate=" +
      this.startval +
      "&endDate=" +
      this.endval +
      "&order=" +
      this.order +
      "&sort=" +
      this.sortName;

    this.associateService
      .get(`associates/history/${this.data.id}` + request, true)
      .subscribe(
        (response) => {
          response.data.forEach((user) => {
            this.history.push(new TransactionHistory(user));
          });
          // setTimeout(() => {
          this.pageDatas.totalItems = response.total;
          this.config.totalItems = response.total;

          // }, 0);

          // this.sharedService.display(false);
          this.contentLoaded = true;
          // this.cd.detectChanges()
        },
        () => {
          this.resetPage();
        }
      );
  }
}
