import { HttpService, SharedService } from "src/app/utils/services";
import {
  Component,
  OnInit,
  ElementRef,
  ChangeDetectorRef,
  Inject,
} from "@angular/core";
import { AssociatesService } from "../../bas/associates/associates.service";
import { ConfirmationComponent } from "src/app/utils/components/confirmation/confirmation.component";
import { Associate, RoleAuth } from "src/app/utils/models";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { SessionStorage } from "ngx-webstorage";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { finalize } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "app-fingerprint",
  templateUrl: "./fingerprint.component.html",
  styleUrls: ["./fingerprint.component.scss"],
})
export class FingerprintComponent implements OnInit {
  public associateForm: UntypedFormGroup;
  public matchedAssociate = new Associate();
  public mismatched = false;
  public currentScanState = "start";
  public isRedFlag = false;
  public isChecked = false;
  public scanStatus = "";
  public scannedImgSrc = "";
  public scanStatusList = {};
  public scannedData = {};
  public selectedRemark: any;
  public selectRemark: any;
  public selectedVendor: any;
  public step = 0; //done: 5, add: 6, update: 7 ,
  public permissions = new RoleAuth([]);
  public showRemarkTxt = false;
  public flag = "";
  public showDropdown = false;
  public govtPropfMinLength = 0;
  public govtPropfMaxLength = 0;
  public siteCode = [];
  public externalId: any;
  public matchedId: any;
  public jobTypes = [];
  public remarks = [];
  public vendors = [];
  public govtIdTypes = [];
  public selectedVendr: any;
  public isFlagEditable = false;
  public associateFromHRIS = false;
  public isExistingAsso = false;
  public manual = false;
  public mismatchErr: any;
  @SessionStorage("auth") public user: any;
  @SessionStorage("mod") public modules: any;
  public flexIdPresentHRIS = false;
  public enrollFailureStatus: any;

  private sdkErrorCodeDesc = {
    2027: "Device not connected.",
    2019: "Capture timeout.",
  };
  public sdkUrl = process.env.SDKURL_STARTEK
  public isRegisterFlowStarted = false;
  public deviceInfo: any = {}
  public fingerprint: any = {
    inProgress: false
  }
  public error = ''
  public success = ''
  public closeButton = false
  public supportEmails = false
  public isOnboardingVisible = true


  constructor(
    private associateService: AssociatesService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    public formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private dialogRef: MatDialogRef<FingerprintComponent>,
    @Inject(MAT_DIALOG_DATA) public assoDataHRIS: any,
    public SharedService: SharedService,
    public HttpServices: HttpService,
    public http: HttpClient
  ) {
    this.externalId = this.assoDataHRIS.external_id;
    this.scanStatusList = this.associateService.scanStatus;
    this.associateForm = this.formBuilder.group({
      first_name: ["", [Validators.required]],
      last_name: [""],
      contact: ["", [Validators.required]],
      agency: [""],
      site: [undefined, [Validators.required]],
      city: ["", [Validators.required]],
      govt_id_proof_type: ["", [Validators.required]],
      govt_id_proof: ["", [Validators.required]],
      job_type: [undefined, [Validators.required]],
      external_id: [""],
      flag: [""],
      remark: ["", [Validators.required]],
      vendor: [""],
      // left_finger: [],
      // right_finger: [],
    });
    this.getVendors();
    this.jobTypes = this.SharedService.jobTypes;
    this.govtIdTypes = this.associateService.govtProofTypes;
    // this.associateForm.disable();
    // this.associateForm.get("first_name").disable();
    // this.associateForm.get("last_name").disable();
    // this.associateForm.get("contact").disable();
    // this.associateForm.get("agency").disable();
    // this.associateForm.get("site").disable();
    // this.associateForm.get("external_id").disable();
    // this.associateForm.get("city").disable();
    // this.associateForm.get("govt_id_proof_type").disable();
    // this.associateForm.get("govt_id_proof").disable();
    // this.associateForm.get("job_type").disable();
  }

  ngOnInit() {
    this.remarks = [
      {
        id: 1,
        remarks: "Flat Finger Registration",
      },
      {
        id: 2,
        remarks: "Others",
      },
    ];
    this.associateFromHRIS = this.SharedService.associateFromHRIS;
    this.checkModulePermissions();
    // to set the flag status to ORANGE whenever amazon/ flex Id is added
    this.associateForm.get("external_id").valueChanges.subscribe((val) => {
      let externalId = val.trim();
      if (
        externalId &&
        (this.associateForm.value.flag === "ORANGE" ||
          this.associateForm.value.flag === "orange")
      ) {
        this.associateForm.patchValue({ flag: "GREEN" });
      } else if (externalId === "" || externalId === null) {
        this.associateForm.patchValue({ flag: this.matchedAssociate["flag"] });
      }
    });

    this.getStationCodes();
  }

  onSelectClick() {
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  onClickOnBoard(showDropdown: boolean) {
    this.isRegisterFlowStarted = true
    this.showDropdown = showDropdown
    if (!showDropdown) {
      this.checkFPServiceConnection()
    }
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  checkFPServiceConnection() {
    this.deviceInfo.error = ''
    this.deviceInfo.loading = "Checking Device Connectivity..."
    this.HttpServices.MorFinAuthClient('connecteddevicelist', '', false)
      .pipe(finalize(() => {
        if (!this.deviceInfo.model) {
          this.deviceInfo.model = 'FM220'
          this.deviceInfo.loading = ''
          this.captureFP(this.deviceInfo.model, 'Left')
        }
        console.log(this.deviceInfo.model)
      }))
      .subscribe(
        data => {
          if (data.ErrorCode == 0 && data.ErrorDescription) {
            this.deviceInfo.model = data.ErrorDescription.split(':')[1].split(',')[0]
            // this.deviceInfo.model = 'MFS500'
            this.initializeFP()
          }

        },
        error => {
          console.log(error)
        }
      )
  }

  initializeFP() {
    const body = {
      "ConnectedDvc": this.deviceInfo.model,
    }
    this.HttpServices.MorFinAuthClient('info', body, true).subscribe(
      data => {
        if (data.ErrorCode == 0) {
          this.deviceInfo.isInitialized = true
          this.deviceInfo.loading = ''
          this.captureFP(this.deviceInfo.model, 'Left')
        }
        else
          this.errorFunc()
      },
      () => this.errorFunc()
    )
  }

  captureFP(device: string, finger: string) {
    this.fingerprint.error = ''
    this.fingerprint.inProgress = true
    this.fingerprint.isCaptured = false
    this.fingerprint.finger = finger
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();

    setTimeout(() => {
      if (device === 'FM220') {
        try {
          this.jsonp(`${this.sdkUrl}gettmpl?callback=?`, (result: any) => {
            this.fingerprint.isCaptured = true
            if (result && result.errorCode == 0) {
              this.successFP(result)
            }
            else if (result && result.errorCode == 103) {
              this.fingerprint.error = {
                ErrorCode: -2019,
                ErrorDescription: this.sdkErrorCodeDesc[2019]
              }
            }
            else {
              this.errorFunc()
            }
            this.changeDetector.detectChanges();
            this.changeDetector.markForCheck();
          });
        } catch (err) {
          this.errorFunc()
        }
      } else {
        this.HttpServices.MorFinAuthClient('capture', {
          "Quality": 100,
          "TimeOut": 10
        }, true).subscribe(
          result => {
            this.fingerprint.isCaptured = true
            if (result.ErrorCode == 0) {
              this.successFP(result)
            } else {
              this.fingerprint.error = result
            }
          },
          () => {
            this.errorFunc()
          })
      }
    }, 100)
  }

  successFP(result: any) {
    // const sample = 'Qk1NAAAAAABAAEAAAABAAEAAAAAAABAAEAAAABAAAAAAABAAAAA'
    // To produce Error
    // if (this.fingerprint[`failureCount${this.fingerprint.finger}`]) this.fingerprint[`failureCount${this.fingerprint.finger}`]--
    // else
    //   this.fingerprint[finger] = `data:image/bmp;base64,${data.BitmapData}`
    const finger = this.fingerprint.finger
    this.fingerprint[finger] = true

    if (this.deviceInfo.model === 'FM220') {
      this.scannedData[finger == 'Right' ? "right_finger" : "left_finger"] = Object.assign(
        [],
        Uint8Array.from(atob(result.wsqBase64), (c) =>
          Number(c.charCodeAt(0))
        )
      );
    } else {
      this.scannedData[this.fingerprint.finger == 'Right' ? "right_finger" : "left_finger"] = Object.assign(
        [],
        Uint8Array.from(atob(result.BitmapData), (c) =>
          Number(c.charCodeAt(0))
        )
      );
    }

    if (this.fingerprint.Left && this.fingerprint.Right) {
      this.fingerprint.inProgress = false
      this.fingerprint.isCompleted = true
      this.scannedData['device_name'] = this.deviceInfo.model
      this.matchData()
    }
  }

  jsonp(url: string, callback?: any) {
    let id = "_" + new Date().getTime();
    (window as any)[id] = (result: any) => {
      if (callback) {
        callback(result);
      }
      let sc: any = document.getElementById(id);
      sc.parentNode.removeChild(sc);
      (window as any)[id] = null;
    };
    url = url.replace("callback=?", "callback=" + id);
    let script = document.createElement("script");
    script.setAttribute("id", id);
    script.setAttribute("src", url);
    script["onerror"] = (err) => {
      this.errorFunc()
    };
    script.setAttribute("type", "text/javascript");
    document.body.appendChild(script);
  }

  refresh() {
    this.isRegisterFlowStarted = false
    this.showDropdown = false
    this.fingerprint = {
      inProgress: false
    }
    this.deviceInfo = {}
    this.error = ''
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }


  deleteFingerPrint() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      height: "200px",
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: "Delete Fingerprint",
        message: `Are you sure to delete ?`,
        buttonLableSubmit: "Yes",
        buttonLableCancel: "No",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteData();
      }
    });
  }

  deleteData() {
    this.associateService
      .delete(`associates/fingerprints/${this.assoDataHRIS.id}`, true)
      .subscribe(
        (res) => {
          this.SharedService.openSnackBar(
            res.failure_cause
              ? { error: res.failure_cause }
              : { success: res.status }
          );
        },
        (err) => { }
      );
  }

  getVendors() {
    let obj = {};
    this.associateService
      .post("vendors", { searchText: "" })
      .subscribe((res) => {
        res.message.Vendors.forEach((ele) => {
          obj = {
            id: ele._id,
            organizationname: ele.organizationname,
            domainname: ele.domainname,
          };

          this.vendors.push(obj);
        });
      });
  }

  vendorSelected(event) {
    this.selectedVendr = event.source.value;
  }

  remarkSelected(event) {
    this.selectRemark = event.source.value;
  }

  regType(event: any) {
    if (event.value == "Biometric Registration") {
      this.checkFPServiceConnection()
    } else {
      this.isOnboardingVisible = false
      this.manual = true;
      this.patchValueToForm();
      this.currentScanState = "add";
    }
    this.showDropdown = false;
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  onClickAdd() {
    this.isOnboardingVisible = false
    this.flag = ''
    this.currentScanState = "add";
    this.matchedAssociate = new Associate();
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  onClickUpdate() {
    this.isOnboardingVisible = false
    this.currentScanState = "update";
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  onClickSave() {
    if (
      this.associateForm.get("first_name").value &&
      this.associateForm.get("contact").value &&
      this.associateForm.get("site").value &&
      this.associateForm.get("city").value &&
      this.associateForm.get("govt_id_proof_type").value &&
      this.associateForm.get("govt_id_proof").value &&
      this.associateForm.get("job_type").value
    ) {
      this.success = 'Saving... Please Wait'
      if (this.associateFromHRIS && this.currentScanState === "add") {
        this.updateAssociate();
      } else {
        if (this.currentScanState === "add") {
          this.enrollAssociate();
        } else {
          this.updateAssociate();
        }
      }
      // this.currentScanState = "requesting";
      // this.scanStatus = "Saving...Please Wait";
      // this.changeDetector.detectChanges();
      // this.changeDetector.markForCheck();
    } else {
      this.SharedService.openSnackBar({ error: "Form is invalid" });
    }
  }


  /* check module permissions */
  checkModulePermissions() {
    if (this.modules) {
      const currentModule = this.modules.filter(
        (module) =>
          module.modulename.toLowerCase() === ("associates" || "hris") &&
          module.resources.filter(
            (resource) =>
              resource.resourcename.toLowerCase() === ("associates" || "hris")
          ).length > 0
      );
      if (currentModule.length > 0) {
        const currentResource = currentModule[0].resources.filter(
          (resource) =>
            resource.resourcename.toLowerCase() === ("associates" || "hris")
        );
        this.permissions = new RoleAuth(currentResource[0].permissions);
      }
    }

    // set whether the flags are editable or not
    // flags are editable if role is 'superadmin' or 'admin' and 'flagging' module has 'update' permissions
    if (
      this.user &&
      this.user.userDetails &&
      this.user.userDetails.role
    ) {
      switch (this.user.userDetails.role.toLowerCase()) {
        case "superadmin":
        case "SuperAdminRead":
        case "vendoradmin": {
          this.isFlagEditable = true;
          break;
        }
      }
    }

    if (this.modules) {
      const userModule = this.modules.filter(
        (module) =>
          module.modulename.toLowerCase() === "flagging" &&
          module.resources.filter(
            (resource) => resource.resourcename.toLowerCase() === "flagging"
          ).length > 0
      );
      if (userModule.length > 0) {
        let permissions = new RoleAuth(userModule[0].resources[0].permissions);
        this.isFlagEditable = permissions.update ? true : this.isFlagEditable;
      }
    }
  }

  contactSupport() {
    this.supportEmails = true;
    this.flag = this.currentScanState = ''
    this.closeButton = true
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  /* Close the popup */
  closeFingerprintModal(status: boolean) {
    this.dialogRef.close(status);
  }

  // base64ToUTF8Array(base64) {
  //   let utf8 = [];
  //   for (let charIndx = 0; charIndx < base64.length; charIndx++) {
  //     let charcode = base64.charCodeAt(charIndx);
  //     if (charcode < 0x80) {
  //       utf8.push(charcode);
  //     } else if (charcode < 0x800) {
  //       utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
  //     } else if (charcode < 0xd800 || charcode >= 0xe000) {
  //       utf8.push(
  //         0xe0 | (charcode >> 12),
  //         0x80 | ((charcode >> 6) & 0x3f),
  //         0x80 | (charcode & 0x3f)
  //       );
  //     }
  //     // surrogate pair
  //     else {
  //       charIndx++;
  //       // UTF-16 encodes 0x10000-0x10FFFF by
  //       // subtracting 0x10000 and splitting the
  //       // 20 bits of 0x0-0xFFFFF into two halves
  //       charcode =
  //         0x10000 +
  //         (((charcode & 0x3ff) << 10) | (base64.charCodeAt(charIndx) & 0x3ff));
  //       utf8.push(
  //         0xf0 | (charcode >> 18),
  //         0x80 | ((charcode >> 12) & 0x3f),
  //         0x80 | ((charcode >> 6) & 0x3f),
  //         0x80 | (charcode & 0x3f)
  //       );
  //     }
  //   }
  //   return utf8;
  // }

  errorFunc(error?: any) {
    if (error) {
      // window.alert(this.sdkErrorCodeDesc[errorCode]);
      this.deviceInfo.loading = false
      this.deviceInfo.error = error
    } else {
      window.alert("Check if FM220, MFS500 or MARC10 service is running");
      this.refresh()
    }
  }

  matchData() {
    // this.scanStatus = 'Matching...Please Wait';
    this.scannedData["site"] = this.associateService.selectedSiteFromAssociate;
    if (this.associateFromHRIS) {
      this.scannedData["id"] = this.assoDataHRIS["id"];
    }

    this.associateService
      .post("associates/match", this.scannedData, true)
      .pipe(
        finalize(() => {
          this.fingerprint = {
            inProgress: false
          }
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        })
      ).subscribe(
        (res) => {
          if (res && res.hasOwnProperty("status")) {
            switch (res.status) {
              case "MATCH_NOT_FOUND":
                this.flag = "green";
                this.currentScanState = "new";
                if (this.associateFromHRIS) this.patchValueToForm();
                break;

              case "GOVT_ID_MISMATCHED":
                this.currentScanState = "mismatch";
                this.mismatched = true;
                this.mismatchErr = res.error_message;
                this.closeButton = true
                break;

              case "MATCH_FOUND":
                if (
                  res.matches &&
                  res.matches.length > 0 &&
                  res.matches[0].hasOwnProperty("associate") &&
                  res.matches[0].associate
                ) {
                  this.matchedId = res.matches[0].associate.external_id;
                  this.matchedAssociate = new Associate(
                    res.matches[0].associate
                  );
                  if (this.associateFromHRIS) {
                    this.isExistingAsso = true;
                    this.patchValueToForm();
                    // this.updateAssociate();
                  } else {
                    this.isExistingAsso = false;
                    this.associateForm.patchValue(res.matches[0].associate);
                  }
                  const obj = {
                    target: {
                      value: this.matchedAssociate.govt_id_proof_type,
                    },
                  };
                  this.checkGovtProof(obj, false);
                  this.currentScanState = "exists";
                  this.isOnboardingVisible = false
                  this.flag = this.matchedAssociate.flag;
                } else {
                  // if fingure matched on nServer but associate data not present in db
                  this.flag = "green";
                  this.currentScanState = "new";
                  if (this.associateFromHRIS) {
                    this.patchValueToForm();
                  }
                }
                break;
              default:
                this.SharedService.openSnackBar({ success: res.status });
                break;
            }
          }
        },
        (err) => {
          this.error = err?.message || "Something went wrong"
          this.closeButton = true
        }
      );
  }

  enrollAssociate() {
    const vendor = {
      org_name: this.user.userDetails.vendor.orgName,
      domain_name: this.user.userDetails.vendor.domainName,
    };
    const created_by = {
      created_by: this.user.userDetails.email,
    };
    let asscData = new Associate(this.associateForm.value);
    asscData["left_finger"] = Object.assign(
      [],
      this.scannedData["left_finger"]
    );
    asscData["right_finger"] = Object.assign(
      [],
      this.scannedData["right_finger"]
    );
    asscData["device_name"] = this.scannedData["device_name"]
    asscData["vendor"] = vendor;
    asscData["created_by"] = this.user.userDetails.email;
    delete asscData["id"];
    delete asscData["flag"];
    asscData["external_id"] =
      asscData["external_id"] !== null ? asscData["external_id"] : "";
    // delete asscData['external_id'];
    this.associateService.post("associates/enroll", asscData, true)
      .pipe(
        finalize(() => {
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        })
      )
      .subscribe(
        (res) => {
          if (res && res.hasOwnProperty("status")) {
            switch (res.status) {

              case "ENROLL_SUCCESS":
                this.success = "Enroll Success";
                this.closeButton = true
                this.storeDataNode();
                break;

              case "ENROLL_FAILED":
                this.enrollFailureStatus = res.failure_cause || "Failed"
                this.SharedService.openSnackBar({
                  error: `ENROLL_FAILED ${res.hasOwnProperty("failure_cause")
                    ? ":" + res.failure_cause
                    : ""
                    }`,
                });
                break;
            }
          }
        },
        (err) => {
          this.success = ''
          this.error = err?.message || "Something went wrong"
        }
      );
  }

  storeDataNode() {
    this.associateService
      .put("associates/transaction", {})
      .subscribe((res) => { });
  }

  updateAssociate() {
    this.associateForm.controls.site = this.assoDataHRIS.site;
    let asscData = new Associate(this.associateForm.value);
    if (asscData.hasOwnProperty("flag")) {
      asscData.flag = asscData.flag.toUpperCase();
    }
    if (asscData.flag == "RED") {
      this.matchedId = asscData.external_id;
    }
    if (this.currentScanState == "update") {
      asscData["update_associate"] = true;
    }
    delete asscData["left_finger"];
    delete asscData["right_finger"];
    let idToPass;
    if (this.associateFromHRIS && !this.isExistingAsso) {
      idToPass = this.assoDataHRIS["id"];
      // delete asscData['external_id'];
      delete asscData["flag"];
      delete asscData["update_associate"];
      //  const vendor = {
      //   org_name: this.userDetails.userDetails.vendor.orgName,
      //   domain_name: this.userDetails.userDetails.vendor.domainName,
      // };
      let vendor;
      if (!this.manual) {
        vendor = {
          org_name: this.user.userDetails.vendor.orgName,
          domain_name: this.user.userDetails.vendor.domainName,
        };
        asscData["remark"] = this.associateForm.value.remarks;
      } else {
        if (this.selectedVendr) {
          let vendorDomain = this.vendors.filter(
            (value) => value.organizationname == this.selectedVendr
          );
          vendor = {
            org_name: this.selectedVendr,
            domain_name: vendorDomain[0].domainname,
          };
        }
        if (this.selectRemark) {
          if (this.selectRemark != "Others") {
            asscData["remark"] = this.selectRemark;
          } else {
            asscData["remark"] = this.associateForm.controls.remark.value;
          }
        }
      }
      asscData["left_finger"] = Object.assign(
        [],
        this.scannedData["left_finger"]
      );
      asscData["right_finger"] = Object.assign(
        [],
        this.scannedData["right_finger"]
      );
      asscData["device_name"] = this.scannedData["device_name"]
      asscData["vendor"] = vendor;
      asscData["created_by"] = this.user.userDetails.email;
    } else if (this.associateFromHRIS && this.isExistingAsso) {
      idToPass = this.matchedAssociate.id;
    } else {
      idToPass = this.matchedAssociate.id;
    }

    delete asscData["id"];

    if (this.associateFromHRIS && asscData["external_id"]) {
      asscData.flag = "GREEN";
    }
    let request;
    if (!this.manual) {
      request = `associates/${idToPass}`;
    } else {
      request = `associates/manual/${idToPass}`;
    }
    this.associateService.put(request, asscData, true)
      .pipe(
        finalize(() => {
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        })
      ).subscribe(
        (res) => {
          this.success = ''
          if (res && res.hasOwnProperty("status")) {
            switch (res.status) {
              case "UPDATE_SUCCESS":
                this.success = "Updated Successfully";
                this.closeButton = true;
                break;

              case "UPDATE_FAILED":
                this.error = "Update Failed";
                this.closeButton = true;
                this.SharedService.openSnackBar({
                  error: `UPDATE_FAILED: ${res.hasOwnProperty("failure_cause")
                    ? ":" + res.failure_cause
                    : ""
                    }`,
                });
                break;

              case "ENROLL_SUCCESS":
                this.success = "Updated Successfully";
                this.closeButton = true;
                break;

              case "ENROLL_FAILED":
                this.currentScanState = ''
                if (res.failure_cause == "DUPLICATE_ID") {
                  this.enrollFailureStatus = `Fingerprint Already Registered with Flex id: ${this.externalId}`;
                } else {
                  this.enrollFailureStatus = res.failure_cause;
                }
                this.closeButton = true
                this.SharedService.openSnackBar({
                  error: `UPDATE_FAILED ${res.hasOwnProperty("failure_cause")
                    ? ":" + res.failure_cause
                    : ""
                    }`,
                });
                break;
            }
            this.associateService.setSubscription(
              "afterOnboradTriggerToList",
              res.status
            );
          }
        },
        (err) => {
          setTimeout(() => {
            this.success = ''
            this.currentScanState = ''
            this.closeButton = true
            this.error = err?.message || "Something went wrong"
          }, 1000)
        }
      );
  }

  checkGovtProof(event, checkTextValue?: boolean) {
    if (checkTextValue) {
      this.associateForm.patchValue({ govt_id_proof: null });
    }
    switch (event.target.value) {
      case "AADHAR":
        this.associateForm
          .get("govt_id_proof")
          .setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
        this.govtPropfMinLength = 12;
        this.govtPropfMaxLength = 12;
        break;

      case "VOTER_ID":
        this.associateForm
          .get("govt_id_proof")
          .setValidators([
            Validators.required,
            Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$"),
          ]);
        this.govtPropfMinLength = 10;
        this.govtPropfMaxLength = 16;
        break;

      case "PANCARD":
        this.associateForm
          .get("govt_id_proof")
          .setValidators([
            Validators.required,
            Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$"),
          ]);
        this.govtPropfMinLength = 10;
        this.govtPropfMaxLength = 10;
        break;

      case "DRIVING_LICENCE":
        this.associateForm
          .get("govt_id_proof")
          .setValidators([
            Validators.required,
            Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$"),
          ]);
        this.govtPropfMinLength = 10;
        this.govtPropfMaxLength = 16;
        break;
    }
  }

  getStationCodes() {
    this.associateService.get("stations").subscribe((res) => {
      this.siteCode = res.payload;
    });
  }

  patchValueToForm() {
    this.associateForm.patchValue({
      first_name: this.assoDataHRIS["first_name"],
      last_name: this.assoDataHRIS["last_name"],
      contact: this.assoDataHRIS["contact"],
      agency: this.assoDataHRIS["agency"],
      site: this.assoDataHRIS["site"],
      city: this.assoDataHRIS["city"],
      govt_id_proof_type: this.assoDataHRIS["govt_id_proof_type"],
      govt_id_proof: this.assoDataHRIS["govt_id_proof"],
      job_type: this.assoDataHRIS["business_title"],
      external_id: this.assoDataHRIS["external_id"],
      flag: this.assoDataHRIS["flag"],
    });
    const obj = {
      target: {
        value: this.assoDataHRIS["govt_id_proof_type"].toUpperCase(),
      },
    };
    if (this.assoDataHRIS["external_id"]) {
      this.flexIdPresentHRIS = true;
    } else {
      this.flexIdPresentHRIS = false;
    }
    this.checkGovtProof(obj, false);
    Object.keys(this.associateForm.controls).forEach((key) => {
      this.associateForm.get(key).markAsDirty();
    });
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  get isButtonEnabled() {
    return !(this.manual && (this.selectedRemark !== 'Others'
      ? (!this.selectedRemark || !this.selectedVendor)
      : (!this.selectedVendor || !this.associateForm.get('remark').valid)));
  }

}
