import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs/";
import { SessionStorage } from "ngx-webstorage";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  @SessionStorage("lp") public landingPage;
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public verify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public loginVerify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public setPassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public roleUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public verifylogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public siteEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public siteCreate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public vendorEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public vendorCreate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public emailCreate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public emailEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public emailDelete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public privacyedit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public logedit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public logcreate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public onboardEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public asscEdit: Subject<boolean> = new Subject();
  public snackbarEmitter: Subject<any> = new Subject();
  public delteUserEmitter: Subject<any> = new Subject();
  public changePassProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public downloadEmitter: Subject<any> = new Subject();

  public dialogRef: any;
  public actionType;
  public jobTypes = [];
  public showSucces;
  public showOTPSucces;
  public siteSuccess;
  public savedDetails: any;
  public associateFromHRIS = false;
  public toggleVal;
  public myData;
  public show;
  public getApprovalCount = new Subject<boolean>();
  public whichPage = "no-page";
  public flag;
  public clearSearch = false;
  public total = false;
  public totalOnboarding = new BehaviorSubject(0);
  public onboardingedit = false;
  public inEditUser = false;
  public inAddUser = false;
  public inUser = false;
  public inAddRole = false;
  public inEditRole = false;
  public vendorName;
  public editId;
  public userData;
  public flagEmit;
  public inVendor = false;
  public inSite = false;
  public userDirty = false;
  public userSites = [];

  public isDirty = false;
  public roleUpdateMess;
  public setPass;
  public overview = false;
  public sites = false;
  public insidePrep = false;
  public profileOverview = false;
  public profileSites = false;
  public userList = new BehaviorSubject([]);
  public statusPage;
  public otpMess;
  public deleteUsers: any[] = [];
  // public landingPage = '/manage-associates/associates';

  constructor() {
    this.landingPage = this.landingPage
      ? this.landingPage
      : "/manage-associates/associates";
  }
  myEvent = new EventEmitter<string>();
  seperateDate = new EventEmitter<any>();
  inactiveFlag = new EventEmitter<string>();
  myEvent1 = new EventEmitter<any>();
  inactiveDate = new EventEmitter<any>();
  redFlagDate = new EventEmitter<any>();
  myEvent2 = new EventEmitter<any>();
  logEvent = new EventEmitter<any>();
  profileEdit = new EventEmitter<any>();
  madeChanges = new EventEmitter<any>();
  edit = new EventEmitter<any>();
  uploadEvent = new EventEmitter<any>();
  userEdit = new EventEmitter<any>();
  user = new EventEmitter<any>();
  otpVerify = new EventEmitter<any>();
  insideAddUser = new EventEmitter<any>();
  userDelete = new EventEmitter<any>();
  updatesuccess = new EventEmitter<any>();
  addUser = new EventEmitter<any>();
  isDelete = new EventEmitter<any>();
  inPrepareAssc = new EventEmitter<any>();
  editHris = new EventEmitter<any>();
  vendorUpdate = new EventEmitter<any>();
  inChangePassword = new EventEmitter<any>();
  fileUpload = new EventEmitter<any>();
  approvalFileUpload = new EventEmitter<any>();
  siteFileUpload = new EventEmitter<any>();
  private getAssociate = new BehaviorSubject<any>({});
  private searchStatus = new BehaviorSubject<any>({});
  display(value: boolean) {
    this.status.next(value);
  }
  toast(value: boolean) {
    this.loginVerify.next(value);
  }

  verifytoast(value: boolean) {
    this.verify.next(value);
  }
  setpage(value: boolean) {
    this.setPassword.next(value);
  }
  roleupdate(value: boolean) {
    this.roleUpdate.next(value);
  }
  siteedit(value: boolean) {
    this.siteEdit.next(value);
  }
  sitecreate(value: boolean) {
    this.siteCreate.next(value);
  }
  vendoredit(value: boolean) {
    this.vendorEdit.next(value);
  }
  vendorcreate(value: boolean) {
    this.vendorCreate.next(value);
  }
  emailcreate(value: boolean) {
    this.emailCreate.next(value);
  }
  emailedit(value: boolean) {
    this.emailEdit.next(value);
  }
  emaildelete(value: boolean) {
    this.emailDelete.next(value);
  }
  privacyEdit(value: boolean) {
    this.privacyedit.next(value);
  }
  logEdit(value: boolean) {
    this.logedit.next(value);
  }
  logCreate(value: boolean) {
    this.logcreate.next(value);
  }
  onboardedit(value: boolean) {
    this.onboardEdit.next(value);
  }
  asscedit(value: boolean) {
    this.asscEdit.next(value);
  }
  changePassword(value: boolean) {
    this.changePassProfile.next(value);
  }
  getSubscription(subsName) {
    let subscription: any;
    switch (subsName) {
      case "getAssociate":
        subscription = this.getAssociate.asObservable();
        break;
      case "search":
        subscription = this.searchStatus.asObservable();
        break;
    }
    return subscription;
  }

  setSubscription(subsName, data) {
    switch (subsName) {
      case "getmyAssociate":
        this.getAssociate.next(data);
        break;
      case "search":
        this.searchStatus.next(data);
        break;
    }
  }
  formData: any = {};
  siteData: any;
  profileData: any = {};
  profilesiteData: any;
  setFormData(data: any) {
    this.formData = data;
  }

  getFormData() {
    return this.formData;
  }
  setProfileData(data: any) {
    this.profileData = data;
  }

  getProfileData() {
    return this.profileData;
  }
  setProfileSiteData(data: any) {
    this.profilesiteData = data;
  }

  getProfileSiteData() {
    return this.profilesiteData;
  }
  setSiteData(data: any) {
    this.siteData = data;
  }

  getSiteData() {
    return this.siteData;
  }

  openSnackBar(data: { success: string } | { error: string }) {
    this.snackbarEmitter.next(data);
  }
}
