import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WebsocketService } from '../../services/websocket.service';
import { AssociatesService } from 'src/app/bas/associates/associates.service';
import * as Papa from "papaparse";
import { SharedService } from '../../services';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'bas-download',
  templateUrl: './download.component.html',
  styleUrl: './download.component.scss'
})
export class DownloadComponent {
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent): void {

    const confirmed = window.confirm("Are you sure you want to refresh? Download is in progress.");
    if (!confirmed) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  public download: {
    isStarted: boolean;
    fileName: string;
    status: string;
    progress: string;
  } = {
      isStarted: false,
      fileName: '',
      status: '',
      progress: ''
    };
  constructor(
    public dialogRef: MatDialogRef<DownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private associateService: AssociatesService,
    private websocketService: WebsocketService,
    private dialog: MatDialog
  ) {

    this.download.fileName = this.data.fileName
    this.download.status = 'processing...'
    this.websocketService.connect(this.data.taskId, 'START_DOWNLOAD').subscribe((response) => {

      if (response.progress) this.download.progress = response.progress;
      if (response.status === 'started') this.download.status = 'processing...';
      if (response.status === 'processing') this.download.status = '';
      if (response.status === 'preparing for download') this.download.status = response.status;
      if (response.status === 'No data found') {
        this.dialogRef.close()
        this.sharedService.openSnackBar({ error: "No Data Found" });
      }
      if (response.status === 'completed') {
        this.download.status = 'Completed'
        this.associateService
          .fileDownload("associates/downloadCSV?fileName=" + response.taskId, true)
          .subscribe((res) => {
            if (res) {
              Papa.parse(res, {
                complete: (result: any) => {
                  const csv = Papa.unparse(result.data);
                  const blob = new Blob([csv], { type: "text/csv" });
                  const link = document.createElement("a");
                  link.setAttribute("href", window.URL.createObjectURL(blob));
                  link.setAttribute("download", this.download.fileName);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                },
              });
              setTimeout(() => {
                this.dialogRef.close()
              }, 500)
            } else {
              this.sharedService.openSnackBar({ error: "No Data Found" });
            }
          },
            (error) => {
              this.sharedService.openSnackBar({ error: "Download Failed" });
            });
      }

    })
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: "Cancel Download",
        message: 'Are you sure you want to cancel the download?',
        buttonLableSubmit: "Yes",
        buttonLableCancel: "No"
      },
    });

    dialogRef.afterClosed().subscribe((status: Boolean) => {
      if (status) {
        this.websocketService.sendMessage(this.data.taskId, 'CANCEL')
        this.dialogRef.close();
      }
    });
  }


}
