import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppCommonService } from "../app-common.service";
import { ConfirmationComponent } from "src/app/utils/components/confirmation/confirmation.component";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
  animateText,
  onSideNavChange,
} from "src/app/utils/animations/animations";
import { DashboardService } from "src/app/bas/dashboard/dashboard.service";
import { PaginationClass } from "src/app/utils/models";
import { SharedService } from "src/app/utils/services";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from '@angular/material/menu';
import { WebsocketService } from "src/app/utils/services/websocket.service";
import * as Papa from "papaparse";
import { AssociatesService } from "src/app/bas/associates/associates.service";

@Component({
  selector: "bas-header-v2",
  templateUrl: "./header-v2.component.html",
  styleUrls: ["./header-v2.component.scss"],
  animations: [onSideNavChange, animateText],
})
export class HeaderV2Component implements OnInit {
  @SessionStorage("auth") public user: any;
  @SessionStorage("uId") public viewableUser: any;
  @SessionStorage("pStatus") public isResetPassword: any;
  @SessionStorage("downloads") public sessionDownloads: any;
  public pageData = new PaginationClass({ pageSize: 10 });
  @Output() collapsed = new EventEmitter<boolean>();
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  public userName = "";
  public classFlag: boolean;
  public side = false;
  public userApprovalCount: any;
  public nav = true;
  public role: any;
  public count: number;
  public vendorName: any;
  public large = false;
  public larger = false;
  public myRoute = "/dashboard/approvals/approval";
  public clicked = false;
  public dropDownMenuList = [
    { menu_name: "My Profile" },
    // { menu_name: 'Change Password' },
    { menu_name: "Sign Out" },
  ];
  public downloads: any = [];

  constructor(
    private router: Router,
    private appCommonService: AppCommonService,
    private sessionStorageService: SessionStorageService,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    public sharedService: SharedService,
    private observer: BreakpointObserver,
    public associateService: AssociatesService,
    private websocketService: WebsocketService
  ) {
    this.sharedService.vendorUpdate.subscribe((res) => {
      this.vendorName = res;
    });
    if (router.url == this.myRoute) {
      this.clicked = true;
    }
    this.observer
      .observe(["(max-width:769px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.classFlag = true;
        } else {
          this.classFlag = false;
        }
      });

    this.sharedService.getApprovalCount.subscribe((val) => {
      if (val) {
        this.dashboardService
          .post("users/approval/count", this.pageData)
          .subscribe((res) => {
            this.count = res.count;
          });
        if (this.count > 9 && this.count <= 99) {
          this.large = true;
        } else if (this.count > 99) {
          this.larger = true;
        }
      }
    });

    // For Multiple Downloads
    // this.sharedService.downloadEmitter.subscribe((data) => {
    //   data.isDownloaded = false
    //   this.websocketService.connect(`wss://bas-dev-api.msystechnologies.net/websocket/${data.taskId}`)
    //   this.downloads.push(data)
    //   this.updateProgress(data.taskId, 'fileName', data.fileName)
    //   this.updateProgress(data.taskId, 'status', 'processing...')
    //   this.websocketService.messages.subscribe((message) => {
    //     if (message) {
    //       const response = JSON.parse(message)

    //       if (response.progress) this.updateProgress(response.taskId, 'progress', response.progress);
    //       if (response.status === 'started') this.updateProgress(response.taskId, 'status', 'processing...')
    //       if (response.status === 'processing') this.updateProgress(response.taskId, 'status', '')
    //       if (response.status === 'preparing for download') this.updateProgress(response.taskId, 'status', response.status)
    //       if (response.status === 'completed') {
    //         this.updateProgress(response.taskId, 'status', response.status)
    //         const currentTask = this.downloads.find((task: any) => task.taskId === response.taskId)
    //         if (!currentTask?.isDownloaded) {
    //           currentTask.isDownloaded = true
    //           this.associateService
    //             .fileDownload("associates/downloadCSV?fileName=" + response.taskId, true)
    //             .subscribe((res) => {
    //               if (res) {
    //                 Papa.parse(res, {
    //                   complete: (result: any) => {
    //                     const csv = Papa.unparse(result.data);
    //                     const blob = new Blob([csv], { type: "text/csv" });
    //                     const link = document.createElement("a");
    //                     link.setAttribute("href", window.URL.createObjectURL(blob));
    //                     link.setAttribute("download", data.fileName);
    //                     document.body.appendChild(link);
    //                     link.click();
    //                     document.body.removeChild(link);
    //                   },
    //                 });
    //               } else {
    //                 this.sharedService.openSnackBar({ error: "No Data Found" });
    //               }
    //             },
    //               (error) => {
    //                 this.sharedService.display(false)
    //               })
    //         }

    //         setTimeout(() => {
    //           this.sessionDownloads = this.downloads = this.downloads.filter((task: any) => task.taskId !== response.taskId)
    //           if (!this.downloads.length) {
    //             this.menuTrigger.closeMenu();
    //           }
    //         }, 500)
    //       }
    //     }
    //   });
    // });

    // if (this.sessionDownloads) {
    //   this.sessionDownloads.forEach((item: any) => {
    //     this.sharedService.downloadEmitter.next(item)
    //   })
    // }
  }

  updateProgress(id: string, key: string, value: any) {
    const download = this.downloads.find((d: any) => d.taskId === id);
    if (download) {
      download[key] = value;
    }
  }


  ngOnInit() {
    this.appCommonService.setSideNav(true);

    this.userName =
      this.user && this.user.userDetails ? this.user.userDetails.name : "";
    this.role = this.user.userDetails.role;
    this.sharedService.getApprovalCount.next(true);
  }

  logout(): void {
    this.router.navigate([""]);
  }

  /* Action to be performed on menu items are clicked */
  onMenuClick(menuName: string) {
    switch (menuName) {
      case "Sign Out":
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          autoFocus: false,
          disableClose: true,
          height: "200px",
          panelClass: "confirm-delete-dialog",
          backdropClass: "confirm-delete-backdrop",
          data: {
            title: "Sign Out",
            message: `Are you sure to Sign Out ?`,
            buttonLableSubmit: "Yes",
            buttonLableCancel: "No",
          },
        });

        dialogRef.afterClosed().subscribe((status: Boolean) => {
          if (status) {
            let mobile = this.user.userDetails.mobile;
            this.appCommonService
              .post(`session/logout?mobile=${mobile}`, {})
              .subscribe((res) => { });
            // Promise.all([this.logout()]).then(() => {
            // this.snackBar.open('Logged out successfully', 'okay', window['snackBarConfig']);
            this.sessionStorageService.clear();
            sessionStorage.clear();
            this.router.navigate([""]);
            // window.location.reload();
            // });
          }
        });
        break;
      case "My Profile":
        this.viewableUser = { user: {} };
        this.isResetPassword = false;
        this.router.navigateByUrl("/profile/my-profile");
        // this.router.navigateByUrl('/profile/change-password');
        break;
      case "My Sites":
        this.viewableUser = { user: {} };
        this.isResetPassword = false;
        this.router.navigateByUrl("/profile/my-sites");
        break;
      case "Change Password":
        this.viewableUser = { user: {} };
        this.isResetPassword = false;
        this.router.navigateByUrl("/profile/change-password");
        break;
    }
  }

  opened() {
    this.collapsed.emit(this.nav);
  }

  disappear() {
    if (this.myRoute) {
      this.sharedService.show = false;
    }
  }
}
