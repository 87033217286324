// import { Injectable } from '@angular/core';
// import { BehaviorSubject, Subject } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class WebsocketService {
//   private socket: WebSocket;
//   public messages: Subject<any> = new Subject();
//   public isDownloaded: boolean = false

//   constructor() { }

//   // Connect to the WebSocket server
//   connect(url: string): void {
//     this.socket = new WebSocket(url);

//     this.socket.onopen = () => {
//       this.socket.send('START_DOWNLOAD');
//     };
//     // When a message is received from the server
//     this.socket.onmessage = (event) => {
//       const message = event.data;
//       this.messages.next(message);  // Push message into BehaviorSubject
//     };

//     // Handle WebSocket errors
//     this.socket.onerror = (error) => {
//       console.error('WebSocket Error: ', error);
//     };

//     // Handle WebSocket connection closure
//     this.socket.onclose = (event) => {
//       if (event.wasClean) {
//         console.log('Closed WebSocket connection');
//       } else {
//         console.error('WebSocket connection error');
//       }
//     };
//   }

//   // Send message to the WebSocket server
//   sendMessage(message: any): void {
//     if (this.socket && this.socket.readyState === WebSocket.OPEN) {
//       this.socket.send(message);
//     } else {
//       console.error('WebSocket is not connected');
//     }
//   }

//   // Close the WebSocket connection
//   disconnect(): void {
//     if (this.socket) {
//       this.socket.close();
//       this.socket = null; 
//     }
//   }
// }

// src\app\services\websocket.service.ts
// src\app\services\websocket.service.ts
// src\app\services\websocket.service.ts
import { Injectable } from "@angular/core";
import { Observable, Observer, Subject } from "rxjs";
import { AnonymousSubject } from "rxjs/internal/Subject";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

export interface Message {
  taskId: string;
  status: string;
  progress: string;
}

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  private baseWsUrl = environment.fingerprintUrl.replace(/^https?/, (match) => {
    return match === 'https' ? 'wss' : 'ws';
  }); // Base URL
  
  private subjects: { [key: string]: AnonymousSubject<MessageEvent> } = {};
  public messages: Subject<Message>;

  constructor() { }

  public connect(id: string, initialMessage?: any): Subject<Message> {
    const url = `${this.baseWsUrl}/websocket/${id}`;

    if (!this.subjects[id]) {
      this.subjects[id] = this.create(url, initialMessage);
    }

    this.messages = <Subject<Message>>this.subjects[id].pipe(
      map((response: MessageEvent): Message => {
        return JSON.parse(response.data);
      })
    );

    return this.messages;
  }

  private create(url: string, initialMessage?: any): AnonymousSubject<MessageEvent> {
    const ws = new WebSocket(url);

    const observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onopen = () => {
        if (initialMessage) {
          ws.send(initialMessage);
        }
      };
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return () => {
        console.log("WebSocket connection closed");
        ws.close();
      };
    });

    const observer = {
      error: null,
      complete: null,
      next: (data: any) => {
        console.log("Message sent to WebSocket:", data);
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        }
      },
    };

    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  public disconnect(id: string): void {
    if (this.subjects[id]) {
      this.subjects[id].unsubscribe(); // Unsubscribe the observable
      delete this.subjects[id]; // Remove the WebSocket reference
      console.log(`Disconnected WebSocket`);
    }
  }

  public sendMessage(id: string, message: any): void {
    if (this.subjects[id]) {
      this.subjects[id].next(message);
      console.log("Message sent:", message);
    } else {
      console.error("WebSocket is not connected for ID:", id);
    }
  }
}
